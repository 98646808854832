<template>
  <b-card>
    <!-- CUIDADO!!!!! NO BORRAR  -->
    <div ref="target" />
    <!-- <div ref="target" style="width: 5px; height: 5px; background-color: black;" /> -->
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <h4>
        {{ $t('backoffice.classifiers.name') }}
      </h4>
      <div class="d-flex">
        <b-button variant="primary" class="ml-1" :disabled="Object.keys(sortedCommunityClassifiers).length <= 0" @click="addClassifier()">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>
    <template 
      v-if="!sortedCommunityClassifiers || Object.keys(sortedCommunityClassifiers).length <= 0">
      {{ $t('members.edit.no-items') }}
    </template>
    <template v-else>
      <b-form-group
        v-for="(value, name, index) in sortedCommunityClassifiers"
        :key="index"
      >
        <label class="font-weight-bold">
          {{ name }}
        </label>
        <v-select
          v-model="selectClassifier[name]"
          :multiple="isMultiple(value)"
          class=" p-0"
          :class="isMultiple(value) ? 'select-multiple' : ''"
          clearable
          :options="value"
          :get-option-label="(a) => getOption(a)"
          :placeholder="isMultiple(value) ? $t('form.select-multiple-placeholder') : $t('form.select-placeholder')"
        />
      </b-form-group>
    </template>
  </b-card>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'

export default {
  name: 'Classifiers',

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },
  
  components: { vSelect },

  mixins: [ToastNotificationsMixin],

  // model: {
  //   prop: 'visible',
  //   event: 'change',
  // },
  props: {
    visible: Boolean,
    morphType: {
      type: String,
      default: '',
    },
    classifiers: {
      type: Array,
      default: () => [],
    },
    morphKey: {
      type: String,
      default: '',
    },
    forSettings: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      selectClassifier: {},
    };
  },

  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    hasMainCommunity() {
      return this.$store.getters.mainCollective && Object.values(this.$store.getters.mainCollective).length > 0;
    },

    // To do: Refactoring with Classifiers.vue
    communityClassifiersData() {
      if (this.$store.getters.classifiers["community"]) {
        return this.$store.getters.classifiers["community"].unpaginated.reduce((acc, current) => {
          const x = acc.findIndex((item) => item.key === current.key);
          if (x === -1) {
            return acc.concat([current]);
          }
          acc[x] = current;
          return acc;
        }, []);
      } else {
        return [];
      }
    },
    parentClassifiersData() {
      if (this.$store.getters.classifiers["parent"]) {
        return this.$store.getters.classifiers["parent"].unpaginated.reduce((acc, current) => {
          const x = acc.findIndex((item) => item.key === current.key);
          if (x === -1) {
            return acc.concat([current]);
          }
          acc[x] = current;
          return acc;
        }, []).filter(({ modelType }) => modelType.includes(this.morphType));
      } else {
        return [];
      }
    },
    communityClassifiers() {
      const communityClassifiersDataValue = Object.values(this.communityClassifiersData);
      console.log('communityClassifiersDataValue', communityClassifiersDataValue);

      const parentClassifiersDataValue = Object.values(this.parentClassifiersData);
      console.log('parentClassifiersDataValue', parentClassifiersDataValue);

      //TODO: hay que añadir lo del classifierTree ?

      return [
        ...communityClassifiersDataValue, 
        ...parentClassifiersDataValue
      ];
    },
    // not used:
    classifierOptions() {
      return this.communityClassifiers.map(({ name }) =>
        translateTranslationTable(this.$store.state.locale.currentLocale, name)
      );
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      this.communityClassifiers.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ] = [item];
        } else if(newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].filter(items => translateTranslationTable(
              this.$store.state.locale.currentLocale,
              items.name
            ) === translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.name
            )).length === 0){
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].push(item);
        }
      });
      return newObject;
    },
  },

  // async created() {
  //   await this.fetchCommunityClassifiers();
  //   this.classifiersPair();
  // },

  watch: {
    targetIsVisible (newValue) {
      // console.log("++++++++++++ --> WATCH!!! Classifiers.vue (targetIsVisible): ", newValue)
      if (newValue && !this.communityClassifiersData.length) {
        // console.log("2 --> WATCH!!! Classifiers.vue (targetIsVisible): ", newValue)
        this.fetchCommunityClassifiers();
        this.fetchClassifiersTree();
        this.classifiersPair();
      }
    },
    classifiers() {
      this.classifiersPair();
    },
  },
  methods: {
    async fetchCommunityClassifiers() {
      await this.$store.dispatch('getItems', {
        storedKey: 'community',
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        forceAPICall: true,
        requestConfig: {
          modelType: this.morphType,
          count: 5000,
          offset: 0,
        },
      });
      if (this.hasMainCommunity) {
        await this.$store.dispatch('getItems', {
          storedKey: 'parent',
          itemType: 'backoffice/classifiers',
          customName: 'classifiers',
          forceAPICall: true,
          communitySlug: this.$store.getters.mainCollective.slug,
          requestConfig: {
            count: 5000,
            offset: 0,
          },
        });
      }
      this.classifiersPair();
    },
    async fetchClassifiersTree(force = false) {
      // console.log('fetching classifiersTree');
      await this.$store.dispatch("getItems", {
        itemType: "classifiersTree",
        storedKey: 'community',
        forceAPICall: force,
        requestConfig: {
          morphType: this.morphType,
          count: 5000,
        },
      });
    },
    async addClassifier() {
      const itemSelected = [];
      let selected = Object.values(this.selectClassifier).filter(
        (item) => item !== null
      );
      selected = selected.map((item) =>
        typeof item === "string" ? [item] : item
      );
      const items = [];
      selected.map(item=>{
        if (Array.isArray(item)) {
          item.map(it => items.push(it))
        } else {
          items.push(item);
        }
      });
      items.map(i=> {
        this.communityClassifiers.map(cc=> {
          if (cc.key === i.key) {
            itemSelected.push(cc.key)
          }
        });
      });
      let response;
      try {
        response = await this.$store.dispatch("AddClassifiers", {
          morphKey: this.morphKey,
          morphType: this.morphType,
          classifierKey: itemSelected,
          items,
        });
        this.selectedClassifier = {};
        this.$emit("change", false);
        this.$emit("profile-updated");
        this.$emit("profileUpdated", itemSelected);
        this.notifySuccess(this.$t("organizations.backoffice.settings.classifiers.messages.success"));
      } catch (error) {
        console.log(error)
      }
      return response;
    },
    translateTranslationTable,
    classifiersPair() {
      const itemClassifiers = {};
      this.classifiers.filter((classifier) => {
        if (
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName
            )
          ]
        ) {
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName,
            )
          ].push(
            classifier,
          );
        } else {
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName,
            )
          ] = [classifier];
        }
      });
      this.selectClassifier = itemClassifiers;
    },
    isMultiple(value) {
      return value.every(({ multiple }) => multiple === true);
    },
    getOption(a) {
      if (typeof a.name === 'object') {
        return a?.name[this.locale] || Object.values(a?.name)[0];
      }
      return a.name;
    },
  },
};
</script>
